@import "./variables.scss";

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88898b;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.dashboard-layout {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row !important;
  overflow: hidden;

  > * {
    flex: 1;
  }

  aside.dashboard-sidebar {
    width: 100%;
    max-width: $aisde_width;
    padding: $page_padding_top $padding_x;
    background: transparent linear-gradient(162deg, #242529 0%, #110e0f 100%) 0%
      0% no-repeat padding-box;
    border-radius: 0px 30px 30px 0px;
    max-height: 100vh;
    transition: all 0.3s;
    position: relative;
    .brand-logo {
      transition: all 0.3s;
      width: 170px;
      display: flex;
      margin: auto;
    }
    .list-group {
      margin-top: 3vh;
      max-height: 100%;
      overflow: hidden;
      overflow-y: scroll;
      .list-group-item,
      &-item-action {
        color: #fff;
        background-color: transparent;
        border: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 1px 0;
        margin: 1vh 0;
        overflow: hidden;
        text-transform: capitalize;
        border-radius: 20px;
        .text {
          position: relative;
          z-index: 10;
        }
        .icon {
          display: block;
          height: 40px;
          width: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          margin-right: 15px;
          z-index: 10;
          position: relative;
          transition: all 0.3s;
          font-size: 16px;
          font-weight: bold;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            background: transparent
              linear-gradient(145deg, #37383f30 0%, #797a7e57 100%) 0% 0%
              no-repeat padding-box;
            transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
          }
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: inherit;
          background: transparent
            linear-gradient(74deg, #37383f30 0%, #797a7e57 100%) 0% 0% no-repeat
            padding-box;
          z-index: 0;
          transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
          transform: scaleX(0);
          transform-origin: left;
        }

        &:hover,
        &.active {
          .icon {
            &::after {
              scale: 1.5;
              opacity: 0;
            }
          }
          &::after {
            transform: scaleX(1);
          }
        }
      }
      .catalogList{
        height: 150px;
        overflow: scroll;
      }
    }
    .heading {
      margin: 1em 0;
      color: #fff;
    }
    .toggle-btn {
      height: 30px;
      width: 30px;
      border-radius: 100%;
      background-color: #fff;
      position: absolute;
      right: -15px;
      top: 50%;
      outline: 1px solid $black;
      box-shadow: 0 0 16px rgb(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      z-index: 99;
      visibility: collapse;
      opacity: 0;
      transition: all 0.3s;
      cursor: pointer;
      img {
        width: 60%;
        height: auto;
        &.menu {
          display: none;
        }
      }
    }

    @media (max-width: 1024px) and (min-width: 768px) {
      max-width: 150px;
      position: absolute;
      height: 100%;
      z-index: 999;
        .brand-logo {
      width: 130px;
    
    }
      .heading {
        text-align: center;
        font-size: 14px;
        letter-spacing: 0.09rem;
      }
     
      .list-group {
        .list-group-item {
          justify-content: center;
          .icon {
            margin-right: 0;
          }
          .text {
            display: none;
          }
        }
      }
      .toggle-btn {
        visibility: visible;
        opacity: 1;
      }

      &.responsive-visible {
        max-width: $aisde_width;
        box-shadow: 0 0 16px rgb(0, 0, 0, 0.1);
        .heading {
          text-align: left;
        }
        .list-group {
          .list-group-item {
            justify-content: flex-start;
            .text {
              display: inline-block;
            }
            .icon {
              margin-right: 15px;
            }
          }
        }
        .toggle-btn {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }

    @media (max-width: 767px) {
      position: absolute;
      height: 100%;
      transform: translateX(-100%);
      z-index: 1;
   .brand-logo {
      width:90px;
    }
      .toggle-btn {
        opacity: 1;
        visibility: visible;
        right: calc((-100vw) + 3vw);
        top: 75px;
        height: 50px;
        width: 50px;
        box-shadow: none;
        img {
          &.arrow {
            display: none;
          }
          &.menu {
            display: block;
          }
        }
      }
      &.responsive-visible {
        z-index: 1;
        transform: translateX(0%);
        .toggle-btn {
          right: calc((-97vw) + $aisde_width);
        }
      }
    }

    @media (max-width: 500px) {
      .toggle-btn {
        width: 30px;
        height: 30px;
      }
    }
  }

  main.main-content {
    max-height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    .dashboard-header {
      padding: 35px 3vw 0px 3vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .nav-greeting {
        .title {
          font-size: 5vmin;
        }
        .tag {
          font-size: 18px;
          margin: 0;
        }
      }
      .nav-buttons {
        display: flex;
        flex-direction: row;
        align-content: center;
        button {
          height: 50px;
          width: 50px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          overflow: hidden;
          outline: 0;
          border: 0;
          background-color: #eeeeee;
          padding: 0;
          margin-left: 15px;
          &.avatar_button {
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }

    section.main-scroll-wrapper {
      padding: $page_padding_top $padding_x;
      overflow: hidden;
      overflow-y: scroll;
      position: relative;
      > .inner {
        height: 100%;
        width: 100%;
      }
    }

    @media (max-width: 1024px) and (min-width: 768px) {
      padding-left: 150px;
    }
  }
}
