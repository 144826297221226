/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Visby Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Regular'), url('./fonts/Fontspring-DEMO-visbycf-regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby light';
    font-style: normal;
    font-weight: normal;
    src: local('Visby light'), url('./fonts/Fontspring-DEMO-visbycf-light.woff') format('woff');
    }
   

    @font-face {
    font-family: 'Visby Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Bold'), url('./fonts/Fontspring-DEMO-visbycf-bold.woff') format('woff');
    }
   


    @font-face {
    font-family: 'Visby Extrabold';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Extrabold'), url('./fonts/Fontspring-DEMO-visbycf-extrabold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Heavy'), url('./fonts/Fontspring-DEMO-visbycf-heavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby thin';
    font-style: normal;
    font-weight: normal;
    src: local('Visby thin'), url('./fonts/Fontspring-DEMO-visbycf-thin.woff') format('woff');
    }
    

   

    /* @font-face {
    font-family: 'Visby Light';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Light'), url('./fonts/VisbyLight.woff') format('woff');
    } */
    

   

    @font-face {
    font-family: 'Visby Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Medium'), url('./fonts/Fontspring-DEMO-visbycf-medium.woff') format('woff');
    }
    

    

    @font-face {
    font-family: 'Visby demibold';
    font-style: normal;
    font-weight: normal;
    src: local('Visby demibold'), url('./fonts/Fontspring-DEMO-visbycf-demibold.woff') format('woff');
    }
    

body {
    font-family: 'Poppins', sans-serif;
    color: #231F20;
    /* overflow: hidden; */
    /* cursor: none; */
}


body:before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(../images/bg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}
body:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 330px;
    background-image: url(../images/bgcloud.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}
.bold {
    font-family: 'Visby Bold';
}
.border-top-1 {
    border-top: 1px solid #d7d7d7;
}

a,
a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Visby demibold';
}

input,
button,
textarea {
    outline: 0 !important;
}
:focus-visible {
    outline: 0;
}
[data-scrollbar] .scrollbar-track-y, [scrollbar] .scrollbar-track-y, scrollbar .scrollbar-track-y {
    opacity: 1;
}
[data-scrollbar] .scrollbar-thumb, [scrollbar] .scrollbar-thumb, scrollbar .scrollbar-thumb {
    background: #fff
}

.opacity-1 {
    opacity: 1 !important;
}
.btn:focus {
    box-shadow: none;
}

.navbar {
    padding: 24px 74px;

}
.section-padding {
    padding: 20px 0px 60px;
}
.navbar-brand img {
    width: 50%;
    
    min-width: 70px;
    max-width: 90px;
    display: block;
}
.navbar-brand {
    width: 40%;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 22px 14px;
    font-size: 16px;
    color: #fff;
}

.navbar-expand-lg .navbar-nav.navbar-right .nav-link {
    font-size: 18px;
    padding: 14px 20px;
}

.navbar-expand-lg .navbar-nav .nav-link.num {
   margin-right: 15px;
    padding: 0 !important;
    font-size: 16px;
}



li.nav-item.num-item img {
    width: 40px;
    margin-right: 10px;
}
.navbar-expand-lg .navbar-nav .nav-link.btn {
    border-radius: 4px;
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #fff;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #c1272d;
}

.navbar-nav {
    align-items: center;
}

.navbar-nav .nav-link {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.navbar-toggler {
    padding: 0;
}
.navbar .mega-dropdown {
    position: unset;
}
.navbar .dropdown-menu {
    background-color: #ffffff;
    border: none;
    padding: 0;
    margin-top: 0;
}
.navbar .large-menu.dropdown-menu {
    left: 15%;
    right: 15%;
    float: left;
    top: 128px;
}
.navbar .large-menu.dropdown-menu .dropdown-section {
    display: flex;
    margin: 20px 0px;
}

.navbar .large-menu.dropdown-menu .dropdown-section .menu-col {
    width: 25%;
    overflow: hidden;
    border-right: 2px solid #ffffff1f;
    padding: 0 30px 0 20px;
}
.navbar .large-menu.dropdown-menu .dropdown-section .menu-col:last-child {
    border: none;
}
.navbar .dropdown-menu .dropdown-section {
    overflow: hidden;
    min-width: 240px;
    margin: 20px 15px;
}
.navbar .dropdown-menu .dropdown-item {
    color: #000000;
    font-size: 14px;
    margin-bottom: 6px;
    padding: 8px 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.navbar .dropdown-menu .dropdown-item:focus, .navbar .dropdown-menu .dropdown-item:hover {
    text-decoration: none;
    background: unset;
    color: #000000;
    background-color: rgb(236, 236, 236);
}
.dropdown button {
    padding: 10px 20px;
}
.btn-custom:hover {
   
    background-color:#efeeee;
    border-color: #000000;
  }
  label{
    color: #4A4A4A;
    font-size: 16px;

  }
  .removeBtn{
    text-align: right;
  }
  .removeBtn button{
        border: none;
            padding: 12px;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 700;
            background-color: #efeeee;
                margin-bottom: 13px;
  }
   

@media (max-width: 991px) {

     .table-wrapper input{min-width: 100px!important}
    .table-wrapper select{min-width: 100px!important}
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }
     .table-wrapper{overflow-x: scroll;}


}

@media (min-width: 992px) {
    
    .navbar-expand-lg .navbar-toggler {
        display: block;
    }
}

@media only screen and (max-width: 650px) {
    .navbar-brand img {
    width: 34%;
    min-width: 70px;
    max-width: 90px;
   
}
    /* .card-body {
    padding: 20px!important;
} */
     .table-wrapper input{min-width: 100px!important}
    .table-wrapper select{min-width: 100px!important}
    .navbar{
        padding: 10px 20px;
   

    }
         .table-wrapper{overflow-x: scroll;}
        
    .dropdown button {
        padding: 5px 10px;
    }


.btn-secondary {
    color: #231F20;
    background-color: transparent;
    border-color: #231F20;
} 
.buttonResponsive button{
    padding: 9px 13px;
    font-size: 12px;

}
.buttonResponsive .form-control{
        font-size: 11px;

}
.addNewItemButton button {
    padding: 9px 13px;
    font-size: 12px;
}


  }

.mr-50 {
    margin-right: 50px;
}

.mt-50 {
    margin-top: 50px;
}

.overflow-hidden {
    overflow: hidden;
}

figure {
    margin: 0;
}



.subHeading {
    
    font-weight: 400;
    font-size: 16px;
    color: #a9afc3;
    text-transform: uppercase;
    margin-bottom: 25px;
    position: relative;
    padding-left: 80px;
    display: inline-block;
}

.subHeading:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 2px;
    background: #585c6b;
    top: 49%;
    left: 0;
}

.mainHeading {
    font-size: 60px;
    
    line-height: 1.2;
    font-weight: 800;
    color: #fff;
    position: relative;
    margin-bottom: 10px;
}

.mainHeading:after {
    content: '';
    position: absolute;
    bottom: 15px;
    background: #b7262c;
    width: 15px;
    height: 15px;
    border-radius: 100px;
    margin-left: 10px;
}
.mainHeading .redColor {
    position: relative;
}
.mainHeading .redColor:before {
    content: '';
    position: absolute;
    top: 24px;
    background: #b7262c;
    width: 30px;
    height: 30px;
    left: 4px;
    right: 0;
    border-radius: 100px;
}
h2.heading {
    font-size: 50px;
    line-height: 1.2;
}

.row{
    justify-content: space-evenly;
        gap: 8px;
}
.heading .redColor {
    position: relative;
}
.heading .redColor:before {
    content: '';
    position: absolute;
    top: 13px;
    background: #b7262c;
    width: 16px;
    height: 15px;
    left: 2px;
    right: 0;
    border-radius: 100px;
}
p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
}

.mainpara {
    font-size: 22px;
}

.form-control { 
    font-size: 16px;
    font-weight: 400;
    background: transparent;
    border: 1px solid #231F2033;
    padding: 12px;
    border-radius: 10px;
    color: #231F20;
}
.form-control::placeholder {
    color: #585c6b;
}
.form-control:focus {
    color: #231F20;
    background-color: transparent;
    border-color: #231f20;
    outline: 0;
    box-shadow: none;
}

.card {
    box-shadow: 0px 6px 22px #00000014;
    border: none;
    border-radius: 30px;
}

.card-body {
    padding: 40px;
}
.form-group {
    margin-bottom: 1.5rem;
}
.btn {
    font-family: 'Visby demibold';
    font-size: 16px;
    border-radius: 10px;
    padding: 12px 30px;
    display: inline-block;
    border: 1px solid #231F20;
}
.btn:focus {
    outline: none;
}
.btn-primary {
    color: #fff;
    background: #231F20;
}

.btn-default {
    border: none;
    background: #efeeee;
    color: #231F20;
}
.btn-default:hover {
    color: #231F20;
}
.btn-primary:focus {
    outline: none;
}
.btn-secondary {
    color: #231F20;
    background-color: transparent;
    border-color: #231F20;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle,.btn-secondary:hover {
    color: #fff;
    background-color: #1f1c1c;
    border-color: #1f1c1c;
}
.btn img {position: relative;top: -2px;margin-right: 4px;}
.btn-primary img {
    filter: invert(1) brightness(2);
}
ul {
    list-style: none;
    padding: 0;
}
.form-lg-group {
    margin-bottom: 34px;
}
img {
    max-width: 100%;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


ul.slick-dots {
    text-align: center;
    margin: 20px 0 0;
}

ul.slick-dots li {
    display: inline-block;
    margin: 0 4px;
}

ul.slick-dots button {
    background: #c50b13;
    font-size: 0;
    height: 20px;
    border-radius: 50%;
    width: 20px;
    opacity: .5;
    border:none;
}

ul.slick-dots li.slick-active button {
    opacity: 1;
}


footer {
    background: #05060a;
    padding: 100px 60px 60px;
}


select.form-control{
    -webkit-appearance: menulist!important;
    -moz-appearance: menulist!important;
    -ms-appearance: menulist!important;
    -o-appearance: menulist!important;
    appearance: menulist!important;
   
}
.addNewItemButton{
    padding: 3px;
}
.addNewItemButton button{
    border: none;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-radius: 9px;
    gap: 4px;
    background-color: black;
    color: white;
}
.addNewItemButton button:hover{
      /* background-color:#efeeee; */
    /* border: 1px solid #000000; */
    color:#57b254

}
.buttonResponsive button{
    display: flex;
    align-items: center;
    gap: 5px;

}
.Print-tableContainer{
    overflow-x: scroll;
}

.user-list tbody td > img {
    position: relative;
	max-width: 50px;
	float: left;
	margin-right: 15px;
}
.user-list tbody td .user-link {
	display: block;
	font-size: 1.25em;
	padding-top: 3px;
	margin-left: 60px;
}
.user-list tbody td .user-subhead {
	font-size: 0.875em;
	font-style: italic;
}


/* TABLES reports */
.reportsTableBody{
        border-spacing: 1px 6px!important;

}
.reportsTableBody > tr > td {
     
	font-size: 0.875em;
    margin: 33px;
    margin-top: 33px;
     border-spacing:10px 15px;
	background-color: #FFFFFF;
	/* border-top: 10px solid #000000db; */
	vertical-align: middle;
	padding: 15px 1px;
}
.reportsTableBody  > tr > td:first-child,
.reportsHead > tr > th:first-child {
	padding-left: 20px;
}
.required-fields{
    font-size: 12px;
    margin-left: 3px;
    color: red;
}
.indidualReportsContainer{
    padding: 20px;
}


